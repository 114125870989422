
import { Component, Vue } from "vue-property-decorator"
import Printable from "@/components/layout/Printable.vue"
import {
  Approval,
  MyInvoiceQuery,
  ConsultantInvoice,
  InvoiceDetailQuery,
  MarkInvoiceAsPaidMutation,
  QueueInvoiceForPaymentMutation,
  InvoiceDetailQueryQuery,
} from "@/gql"
import ActivityTimeline from "@/components/widgets/timeline/ActivityTimeline.vue"
import EditConsultantInvoiceForm from "@/components/forms/EditConsultantInvoiceForm.vue"
import { ApolloError, ApolloQueryResult, NetworkStatus } from "@apollo/client/core"
import FeedBackMessage from "@/components/content/FeedBackMessage.vue"
import Loader from "@/components/widgets/common/Loader.vue"
import { SmartQuery } from "vue-apollo/types/vue-apollo"

@Component({
  components: {
    Printable,
    ActivityTimeline,
    EditConsultantInvoiceForm,
    FeedBackMessage,
    Loader,
  },
})
export default class InvoiceDetail extends Vue {
  fetchError = false
  loading = false
  mutating = false
  showEditInvoiceForm = false
  showMarkAsPaidDialog = false
  showQueuePaymentDialog = false
  showSubmitDialog = false
  loadingError = false
  networkError = false

  invoice: ConsultantInvoice | null = null

  get query() {
    return this.can("view_consultant_invoices") ? InvoiceDetailQuery : MyInvoiceQuery
  }

  get queryVars() {
    return { filter: { idIn: [this.$route.params.id] } }
  }

  get ownApproval(): Approval | undefined {
    return this.invoice?.approvals?.find((a) => a.user.id === this.currentUser?.id)
  }

  getTotal(quantity: string, unitCost: string): string {
    return (parseInt(quantity) * parseFloat(unitCost)).toFixed(2)
  }

  onResult(result: ApolloQueryResult<any>) {
    if (result.networkStatus === NetworkStatus.ready) {
      this.loading = false
      this.fetchError = false

      if (result.data) {
        this.invoice = this.can("view_consultant_invoices")
          ? result.data.consultantInvoices.data[0]
          : result.data.consultantProfile.consultantInvoices.data[0]
      }
    } else if (result.networkStatus === NetworkStatus.error) {
      this.fetchError = true
    }
  }

  onError(error: ApolloError) {
    if (error.graphQLErrors) {
      this.loadingError = true
    } else if (error.networkError) {
      this.networkError = true
    }
  }

  async queueForPayment() {
    this.mutating = true

    const result = await this.mutate({
      mutation: QueueInvoiceForPaymentMutation,
      variables: {
        invoiceId: this.invoice?.id,
      },
      refetchQueries: [
        {
          query: this.query,
          variables: { ...this.queryVars },
        },
      ],
      done: () => {
        this.mutating = false
        this.showQueuePaymentDialog = false
      },
    })

    if (result.data?.queueInvoiceForPayment.id) {
      this.addSuccess("Invoice queued for payment")
    }
  }

  async markInvoiceAsPaid() {
    this.mutating = true

    const result = await this.mutate({
      mutation: MarkInvoiceAsPaidMutation,
      variables: {
        invoiceId: this.invoice?.id,
      },
      refetchQueries: [
        {
          query: this.query,
          variables: { ...this.queryVars },
        },
      ],
      done: () => {
        this.mutating = false
        this.showMarkAsPaidDialog = false
      },
    })

    if (result.data?.markInvoiceAsPaid.consultantInvoice) {
      this.addSuccess("Invoice marked as paid")
    }
  }

  onClose() {
    this.routeBack(this.$routes.Invoices)
  }

  onSubmitInvoice() {
    this.showSubmitDialog = false
    this.showEditInvoiceForm = true
  }

  async handleLoadingErrorButtonClick(query: SmartQuery<InvoiceDetailQueryQuery>) {
    this.$set(this, "loading", true)
    await query.refetch()
  }
}
